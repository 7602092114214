<template>
  <div v-if="pageReady">
    <div>
      <preview
        v-if="showPreview"
        :invoice-data="invoiceData"
        @close="showPreview=false"
      />
    </div>
    <div v-if="!showPreview">

      <upgrade-plan-modal ref="refUpgradePlanModal" />
      <check-invoice-design-modal ref="refCheckInvoiceDesignModal" />
      <b-overlay
        id="overlay-background"
        :show="pageIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >

        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>

        <b-container
          v-if="invoiceData"
          fluid
          class="mt-2"
        >
          <div class="d-flex justify-content-between align-items-center column-is-mobile">
            <b-link

              @click="goToBack"
            > <feather-icon
              icon="ArrowLeftIcon"
              size="30"
              class="mr-1"
            />

            </b-link>
            <h2 class="mb-2 mt-2 ml-2 font-weight-bolder">
              {{ pageTitle }}
            </h2>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="lg"
                variant="warning"
                @click="previewInvoice"
              >
                Preview
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="lg"
                variant="primary"
                class="ml-4"
                @click="saveData"
              >
                Save
              </b-button>
            </div>
          </div>
          <b-row>
            <b-col
              lg="9"
              md="12"
            >
              <status-alert
                :is-success="isSuccess"
                :error-data="errorData"
                :success-msg="successMsg"
              />
              <b-tabs v-model="tabIndex">
                <b-tab title="Edit">
                  <b-card v-if="invoiceData">
                    <!-- form -->
                    <b-alert

                      show

                      fade
                      class="mb-0"
                      variant="secondary"
                    >
                      <div class="alert-body " />
                    </b-alert>
                    <b-list-group flush>
                      <b-list-group-item
                        v-b-hover="clientHoverHandle"
                      >
                        <!-- if client added -->
                        <div
                          v-if="invoiceData.content.billing"
                          class="d-flex justify-content-between align-items-center"
                        >
                          <div class="d-flex flex-column">
                            <h4 class="">
                              {{ invoiceData.content.billing.name }}
                            </h4>
                            <span class="text-muted">  {{ invoiceData.content.billing.email }}</span>
                          </div>
                          <div>
                            <b-button
                              v-if="clientHovered"
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="flat-primary"
                              @click="editClient()"
                            >
                              Edit
                            </b-button>
                            <b-button
                              v-if="clientHovered"
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="flat-danger"
                              @click="deleteClient"
                            >
                              Delete
                            </b-button>
                          </div>
                        </div>

                        <div>
                          <client-selector
                            ref="clientSelector"
                            :selected-client="invoiceData.content.billing"

                            @clientSelected="clientSelected"
                            @clientDeleted="deleteClient"
                          />
                        </div>
                      <!-- if client added -->
                      </b-list-group-item>

                    </b-list-group>
                    <div
                      v-if="!invoiceData.content.billing "
                      class="d-flex justify-content-between align-items-center mb-2 ml-1"
                    >

                      <b-link
                        class="d-flex align-items-center justify-content"
                        @click="$refs.clientSelector.showEditor(null)"
                      > <feather-icon
                        icon="UsersIcon"

                        size="30"
                      />  <strong style="font-size:16px; padding:10px;"> Add a client</strong></b-link>
                    </div>
                    <b-alert

                      show

                      fade
                      class="mb-0"
                      variant="secondary"
                    >
                      <div class="alert-body " />
                    </b-alert>
                    <b-col>
                      <b-list-group class="mt-1">
                        <draggable
                          v-model="invoiceData.content.items"
                          handle=".dragbtn"
                        >
                          <transition-group
                            type="transition"
                            name="flip-list"
                          >
                            <b-list-group-item
                              v-for="item,index in invoiceData.content.items"
                              :key="index"
                              button
                              @click="editProduct(item,index)"
                            >
                              <!-- if client added -->
                              <div

                                class="d-flex justify-content-between align-items-center"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <feather-icon
                                    icon="MoveIcon"
                                    class="mr-2 dragbtn"
                                    style="cursor:grab;"
                                    size="30"
                                  />
                                  <div class="d-flex flex-column">
                                    <h5 class="font-weight-bolder">
                                      {{ item.name }}
                                    </h5>
                                    <span
                                      v-if="item.description"
                                      class="text-muted"
                                    >  {{ item.description }}</span>
                                    <div class="d-flex justify-content-between align-items-center">
                                      <div class="d-flex flex-column">
                                        <span>{{ item.quantity }} {{ item.unit_type }} x {{ invoiceData.content.currency_symbol?invoiceData.content.currency_symbol:invoiceData.content.currency_code }} {{ $staticParams.formatCurrency(item.unit_price) }}</span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex flex-column">
                                  <span :class="item.discount?'text-muted old-price-text':''">{{ invoiceData.content.currency_symbol?invoiceData.content.currency_symbol: invoiceData.content.currency_code }} {{ $staticParams.formatCurrency(item.quantity*item.unit_price) }}</span>
                                  <span v-if="item.discount">{{ invoiceData.content.currency_symbol?invoiceData.content.currency_symbol:invoiceData.content.currency_code }} {{ $staticParams.formatCurrency(handleItemDiscount( item)) }}</span>

                                </div>
                              </div>
                            </b-list-group-item>
                          </transition-group>
                        </draggable>
                        <div>
                          <product-selector
                            v-if="taxesData"
                            ref="productSelector"
                            :taxes-data="taxesData"
                            :currency="invoiceData.content.currency_symbol!=''?invoiceData.content.currency_symbol:invoiceData.content.currency_code"
                            @productSelected="productSelectedHandler"
                            @productDeleted="productDeletedHandler"
                          />
                        </div>
                        <div

                          class="d-flex justify-content-between align-items-center mb-2 mt-2"
                        >

                          <b-link

                            class="d-flex align-items-center justify-content"
                            @click="$refs.productSelector.showEditor(null,-1)"
                          > <feather-icon
                            icon="PlusCircleIcon"

                            size="30"
                          />  <strong style="font-size:16px; padding:10px;"> Add Products</strong></b-link>
                        </div>

                      </b-list-group>
                    </b-col>
                    <b-alert

                      show

                      fade
                      class="mb-0"
                      variant="secondary"
                    >
                      <div class="alert-body " />
                    </b-alert>
                    <b-col>
                      <file-uploader
                        ref="photoUploader"
                        mode="multi"
                        :accepts="'.jpg, .png, .jpeg'"
                        @fileUploaded="photoUploaded"
                      />
                      <b-list-group class="mt-1">
                        <b-list-group-item
                          v-for="item,index in invoiceData.content.attachments"
                          :key="index"
                        >
                          <!-- if client added -->
                          <div

                            class="d-flex justify-content-between align-items-center"
                          >
                            <div class="d-flex flex-row align-items-center">

                              <div class="d-flex flex-column">
                                <b-img
                                  fluid
                                  width="100"

                                  rounded
                                  :src="item.url"
                                  :alt="item.original_name"
                                />

                              </div>
                              <div
                                v-if="!item.editMode"
                                class="d-flex flex-column ml-2"
                              >

                                <span class="font-weight-bolder">  {{ item.original_name }}</span>
                                <p class="mt-1">
                                  {{ item.description }}
                                </p>
                              </div>
                              <div
                                v-if="item.editMode && selectedEditAttachment"
                                class="d-flex flex-column ml-2"
                              >
                                <b-form-group
                                  label="Title"
                                  label-cols-md="4"
                                  label-for="attachment-name"
                                >

                                  <b-form-input
                                    id="attachment-name"
                                    v-model="selectedEditAttachment.original_name"
                                  />
                                </b-form-group>
                                <b-form-group
                                  label-cols-md="4"
                                  label="Description"
                                  label-for="attachment-description"
                                >
                                  <b-form-input
                                    id="attachment-description"
                                    v-model="selectedEditAttachment.description"
                                  />
                                </b-form-group>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                              <b-button
                                v-if="!item.editMode"
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="flat-danger"
                                @click="deleteAttachment(index)"
                              >
                                Delete
                              </b-button>
                              <b-button
                                v-if="!item.editMode"
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="flat-primary"
                                @click="editAttachment(item)"
                              >
                                Edit
                              </b-button>

                              <b-button
                                v-if="item.editMode"
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="flat-danger"
                                @click="cancelEditAttachment(item)"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-if="item.editMode"
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="flat-primary"
                                @click="saveEditAttachment(item)"
                              >
                                Save
                              </b-button>
                            </div>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                      <b-link

                        class="d-flex align-items-center justify-content mb-1 mt-1"
                        @click="addPhotos"
                      > <feather-icon
                        icon="CameraIcon"

                        size="30"
                      /><strong style="font-size:16px; padding:10px;"> Add photos</strong></b-link>
                    </b-col>
                    <b-alert

                      show

                      fade
                      class="mb-0"
                      variant="secondary"
                    >
                      <div class="alert-body " />
                    </b-alert>
                    <b-col md="6">
                      <b-form-group
                        class="mt-2"
                        label="Comment"
                        label-for="comment"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="comment"
                          v-model="invoiceData.content.comment"
                          size="lg"
                          placeholder="Enter a comment for your customer"
                        />
                      </b-form-group>
                    </b-col>

                  </b-card>
                </b-tab>
                <b-tab title="Send invoice">
                  <b-card>
                    <invoice-send
                      v-if="invoiceData.id!=0"
                      :invoice-data="invoiceData"
                      @handleSend="handleInvoiceSend"
                    />
                    <h1 v-if="invoiceData.id==0">
                      Before you can send the invoice, please save it.
                    </h1>
                  </b-card>
                </b-tab>
                <b-tab
                  v-if="true"

                  title="Recurring Settings"
                >
                  <b-card>
                    <recurring-invoice-settings
                      :invoice-data="invoiceData"
                      @handleRecurringInvoice="handleRecurringInvoice"
                    />
                  </b-card>
                </b-tab>
              </b-tabs>
            </b-col>
            <b-col>
              <b-row class="mt-3">
                <b-col
                  md="12"
                  lg="12"
                  class="d-flex flex-column"
                >
                  <b-card>
                    <app-collapse :visible="false">
                      <app-collapse-item title="Invoice Summary">
                        <h4 class=" font-weight-bolder">
                          #{{ invoiceData.content.invoice_number }}
                        </h4>
                        <validation-observer

                          ref="formClient"
                        > <b-form>
                          <validation-provider
                            #default="{errors}"
                            :name="$t('Date')"
                            rules="required"
                          >
                            <!-- Name -->
                            <b-col
                              cols="12"
                              class="p-0"
                            >

                              <b-form-group
                                label="Date"
                                label-for="invoice-date"
                                label-cols-md="4"
                                class="font-weight-bolder"
                              >
                                <b-form-datepicker
                                  id="invoice-date"
                                  v-model="invoiceData.content.invoice_date"
                                  size="sm"
                                  local="en"
                                  today-variant="danger"
                                  :state="errors.length > 0 ? false:null"
                                  :date-format-options="dateFormatOptions"
                                  :style="dynamicStyle"
                                  @input="changeDueDateFromTerm"
                                />

                                <b-form-invalid-feedback>
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>

                            </b-col>
                          </validation-provider>
                          <validation-provider
                            #default="{errors}"
                            :name="$t('Terms')"
                            rules="required"
                          >
                            <!-- Name -->
                            <b-col
                              cols="12"
                              class="p-0"
                            >

                              <b-form-group
                                label="Terms"
                                label-for="invoice-terms"
                                label-cols-md="4"
                                class="font-weight-bolder"
                              >
                                <v-select
                                  v-model="selectedTerms"
                                  size="sm"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="termsOptions"
                                  :reduce="val => val.value"
                                  autocomplete="chrome-off"
                                  :clearable="false"
                                  input-id="invoice-terms"
                                  :state="errors.length > 0 ? false:null"
                                  @input="changeDueDateFromTerm"
                                >
                                  <template #option="{ value }">

                                    <span> {{ value==0?'Same day':value+' days' }}</span>
                                  </template>
                                  <template #selected-option="{value }">

                                    <span> {{ value==0?'Same day':value+' days' }}</span>
                                  </template>
                                </v-select>
                                <b-form-invalid-feedback>
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>

                            </b-col>
                          </validation-provider>
                          <validation-provider
                            #default="{errors}"
                            :name="$t('Due date')"
                            rules="required"
                          >
                            <!-- Name -->
                            <b-col
                              cols="12"
                              class="p-0"
                            >

                              <b-form-group
                                label="Due date"
                                label-for="invoice-due-date"
                                label-cols-md="4"
                                class="font-weight-bolder"
                              >
                                <b-form-datepicker
                                  id="invoice-due-date"
                                  v-model="invoiceData.content.due_date"
                                  size="sm"
                                  local="en"
                                  today-variant="danger"
                                  :state="errors.length > 0 ? false:null"
                                  :date-format-options="dateFormatOptions"
                                  :style="dynamicStyle"
                                  @input="setTermsFromDueDate"
                                />
                                <b-form-invalid-feedback>
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>

                            </b-col>
                          </validation-provider>
                          <hr>
                          <b-row class="d-flex flex-column">
                            <b-col class="d-flex justify-content-between align-items-center mb-1">
                              <h5 class="font-weight-bolder">
                                Subtotal
                              </h5>
                              <span class="price-span"> {{ calculatedInvoiceData.content.currency_code }}  {{ $staticParams.formatCurrency(calculatedInvoiceData.content.totals.subtotal) }}</span>
                            </b-col>
                            <discount-selector

                              ref="discountSelector"
                              :currency="invoiceData.content.currency_symbol"
                              @discountSaved="discountEditedHandler"
                            />
                            <b-col
                              class="d-flex justify-content-between align-items-center mb-1"
                              style="cursor:pointer;"
                              @click="editDiscount"
                            >

                              <h5 class="font-weight-bolder">

                                Discount
                              </h5>
                              <span class="discount-span">{{ calculatedInvoiceData.content.currency_code }}  {{ $staticParams.formatCurrency(calculatedInvoiceData.content.totals.discount) }}</span>
                            </b-col>
                          </b-row>
                          <hr>
                          <b-row class="d-flex flex-column">
                            <tax-editor

                              ref="taxEditor"
                              @saved="taxDataEditHandler"
                            />
                            <b-col
                              v-for="singleTax,taxIndex in calculatedInvoiceData.content.totals.taxes"
                              :key="taxIndex"
                              class="d-flex flex-column "
                            >
                              <div class="d-flex justify-content-between align-items-center mb-1">
                                <h5 class="font-weight-bolder">
                                  {{ singleTax.tax_name }}
                                </h5>
                                <b-link @click="editTax(singleTax)">
                                  Edit
                                </b-link>
                              </div>
                              <div class="d-flex justify-content-between align-items-center mb-1">
                                <h5>{{ singleTax.tax_rate }}% of {{ calculatedInvoiceData.content.currency_code }} {{ $staticParams.formatCurrency(calculatedInvoiceData.content.totals.subtotal-calculatedInvoiceData.content.totals.discount) }}</h5>
                                <span class="price-span text-right mb-1">   {{ calculatedInvoiceData.content.currency_code }} {{ $staticParams.formatCurrency(singleTax.amount) }}</span>
                              </div>
                            </b-col>
                          </b-row>

                          <b-row class="d-flex flex-column">

                            <b-col class="d-flex justify-content-between align-items-center mb-1">
                              <h5 class="font-weight-bolder">
                                Total
                              </h5>
                              <span class="price-span">{{ calculatedInvoiceData.content.currency_code }}  {{ $staticParams.formatCurrency(calculatedInvoiceData.content.totals.total) }}</span>
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="calculatedInvoiceData.content.paid_date!=null"
                            class="d-flex flex-column"
                          >

                            <b-col class="d-flex justify-content-between align-items-center mb-1">
                              <h5 class="font-weight-bolder">
                                Paid
                              </h5>
                              <span class="price-span">{{ calculatedInvoiceData.content.currency_code }}  {{ $staticParams.formatCurrency(calculatedInvoiceData.content.totals.paid) }}</span>
                            </b-col>
                          </b-row>
                          <b-row class="d-flex flex-column">

                            <b-col class="d-flex justify-content-between align-items-center mb-1">
                              <h5 class="font-weight-bolder">
                                Balance
                              </h5>
                              <span class="price-span">{{ calculatedInvoiceData.content.currency_code }}  {{ $staticParams.formatCurrency(calculatedInvoiceData.content.totals.balance) }}</span>
                            </b-col>
                          </b-row>
                        </b-form>
                        </validation-observer>                    </app-collapse-item>
                    </app-collapse>

                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between align-items-center column-is-mobile">

            <h2 class="mb-2 mt-2 ml-2 font-weight-bolder" />
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="lg"
                variant="warning"
                @click="previewInvoice"
              >
                Preview
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="lg"
                variant="primary"
                class="ml-4"
                @click="saveData"
              >
                Save
              </b-button>
            </div>
          </div>
        </b-container>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormInvalidFeedback, BOverlay, BSpinner, BTabs, BTab, BContainer, BLink, BAlert, BListGroup, BListGroupItem, VBHover, BFormDatepicker, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import {
  onUnmounted,
} from '@vue/composition-api'
import draggable from 'vuedraggable'
import invoiceCalculations from '@core/components/invoice-themes/calculations'
import vSelect from 'vue-select'
import fileUploader from '@core/components/file-uploader/FileUploader.vue'
import checkInvoiceDesignModal from '@core/components/information-components/CheckInvoiceDesignModal.vue'
import upgradePlanModal from '@core/components/information-components/UpgradePlanModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle, no-unused-vars
import useJwt from '@/auth/jwt/useJwt'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import invoiceStoreModule from './invoiceStoreModule'
import clientSelector from './ClientSelector.vue'
import productSelector from './ProductSelector.vue'
import discountSelector from './DiscountSelector.vue'
import taxEditor from './TaxEditor.vue'
import invoiceSend from './InvoiceSend.vue'
import recurringInvoiceSettings from './recurring/RecurringInvoiceSettings.vue'
// eslint-disable-next-line import/no-cycle
import preview from './Preview.vue'

export default {
  components: {
    BButton,
    recurringInvoiceSettings,
    preview,
    AppCollapse,
    AppCollapseItem,
    checkInvoiceDesignModal,
    invoiceSend,
    upgradePlanModal,
    fileUploader,
    BForm,
    vSelect,
    discountSelector,
    BTabs,
    draggable,
    BContainer,
    BImg,
    taxEditor,
    BLink,
    productSelector,
    BAlert,
    BTab,
    BFormGroup,
    BFormDatepicker,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay,
    BSpinner,
    BListGroup,
    BListGroupItem,
    clientSelector,
  },
  directives: {
    Ripple,
    'b-hover': VBHover,
  },
  props: {
    activeTab: {
      type: String,
      default: 'edit',
    },
  },
  data() {
    return {
      pageReady: false,
      tabIndex: 0,
      pageTitle: 'Create a invoice',
      previewNotReady: true,
      showPreview: false,
      termsOptions: [],
      blankTermsOptions: [
        { value: 0, label: 0 },
        { value: 7, label: 7 },
        { value: 14, label: 14 },
        { value: 21, label: 21 },
        { value: 30, label: 30 },
        { value: 45, label: 45 },
        { value: 60, label: 60 },
        { value: 90, label: 90 },
      ],
      selectedEditAttachment: null,
      clientHovered: false,
      pageIsLoading: true,
      overlayMsg: 'Loading',
      currencyClaveOptions: null,
      selectedTerms: 0,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Invoice Updated',
      errorData: null,
      taxesData: null,
      userData: null,
      blankInvoiceData: {
        id: 0,
        design: null,
        content: {
          paid_date: null,
          paid_amount: 0,
          attachments: [],
          comment: '',
          discount: null,
          currency_code: 'ALL',
          billing: null,

          invoice_date: null,
          due_date: null,
          invoice_number: '1',
          taxes: [],
          withholding_tax: {
            name: 'Withholding tax',
            rate: 10,
          },
          items: [],
          shipping: null,

          company_info: {
            name: 'Fast Invoice inc',
            additional_information: '',
            address: 'Fast invoice canada office',
            email: 'info@billingapp',
            website: 'billingapp.com',
            phone: '0 5551234567',
            tkn: 'test-tkn-number',

          },
        },
        recurringData: null,

      },
      invoiceData: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    calculatedInvoiceData() {
      return invoiceCalculations.calculateTotals(this.invoiceData)
    },

    dateFormatOptions() {
      if (this.windowWidth >= 990 && this.windowWidth <= 1900) {
        return { year: 'numeric', month: '2-digit', day: '2-digit' }
      }

        return { year: 'numeric', month: 'long', day: 'numeric' }
    },
    dynamicStyle() {
      if (this.windowWidth > 990 && this.windowWidth < 1220) {
        return { fontSize: 'xx-small' }
      }
        return { fontSize: 'inherit' }
    },

  },

  created() {
    this.pageReady = true
    this.$userHandler.setUsageStep('first_invoice_created')

    this.resetForm()
    this.resetTermsOptions()

    let invoiceId = 0

    this.invoiceData.id = invoiceId

    if (this.$router.currentRoute.params.id) {
      invoiceId = this.$router.currentRoute.params.id
      this.pageTitle = 'Edit invoice'
      this.fetchInvoice(invoiceId)
    } else {
      this.fetchAccountData()
      this.pageIsLoading = false
      const today = this.$moment()
      this.invoiceData.content.invoice_date = today.format('YYYY-MM-DD')
      this.invoiceData.content.due_date = today.format('YYYY-MM-DD')
    }
    // console.log(this.activeTab)
  if (this.activeTab === 'send' || this.$route.query.activetab === 'send') {
      this.tabIndex = 1
    } else if (this.activeTab === 'edit' || this.$route.query.activetab === 'edit') {
      this.tabIndex = 0
    }
  },
  mounted() {
    if (!this.$userHandler.checkAppUsageStep('invoice_design_checked')) {
      this.$refs.refCheckInvoiceDesignModal.show()
    }
    window.addEventListener('resize', this.updateWindowWidth)
  },
  beforeDestroy() {
    // Clean up event listener
    window.removeEventListener('resize', this.updateWindowWidth)
  },
  methods: {
     updateWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    goToBack() {
      this.$destroy()
      this.$router.replace({ name: 'invoices' })
    },
    // eslint-disable-next-line no-unused-vars
    handleRecurringInvoice(recurringData) {
     this.invoiceData.recurringData = recurringData
     this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Settings have been saved.',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
    },

    handleInvoiceSend(sendData) {
      // this.saveData()
      this.sendInvoiceMail(sendData)
    },
    sendInvoiceMail(mailContent) {
      this.overlayMsg = 'Invoice sending to mail...'
      this.pageIsLoading = true
      store
        .dispatch('app-invoice/sendMail', { invoice_id: this.invoiceData.id, content: mailContent })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isSuccess = true
          this.errorData = null
          this.successMsg = 'Invoice sended'
          this.pageIsLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice sent by e-mail',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$destroy()
          this.$router.replace({ name: 'invoices' })
        })
        .catch(error => {
          this.pageIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    previewInvoice() {
      this.overlayMsg = 'Preview downloading...'
      /*

      const excelUrl = `${process.env.VUE_APP_API_URL}/api/invoices-download/${this.invoiceData}?token=${useJwt.getToken()}`
      window.location.href = `${excelUrl}`
     */
      this.pageIsLoading = true
      this.pageIsLoading = false
      this.showPreview = true
      // setTimeout(() => this.$refs.html2Pdf.generatePdf(), 1000)
    },
    pdfHasStartedGeneration() {
      this.previewNotReady = true
    },
    pdfHasGenerated() {
      this.pageIsLoading = false

      this.previewNotReady = false
    },
    saveEditAttachment(attachment) {
      // eslint-disable-next-line no-param-reassign
      attachment.description = this.selectedEditAttachment.description

      // eslint-disable-next-line no-param-reassign
      attachment.original_name = this.selectedEditAttachment.original_name
      this.selectedEditAttachment = null

      // eslint-disable-next-line no-param-reassign
      attachment.editMode = false
    },
    cancelEditAttachment(attachment) {
      // eslint-disable-next-line no-param-reassign
      attachment.editMode = false

      this.selectedEditAttachment = null
    },
    deleteAttachment(index) {
      this.invoiceData.content.attachments.splice(index, 1)
    },
    editAttachment(attachment) {
      // eslint-disable-next-line no-param-reassign
      attachment.editMode = true
      this.selectedEditAttachment = JSON.parse(JSON.stringify(attachment))
    },
    photoUploaded(fileData) {
      const photoData = {
        file_id: fileData.id,
        url: fileData.url,
        original_name: fileData.original_file_name,
        file_name: fileData.file_name,
        description: '',
        folder_prefix: fileData.folder_prefix,
        editMode: false,
      }
      this.invoiceData.content.attachments.push(photoData)
    },
    addPhotos() {
      this.$refs.photoUploader.openUploader()
    },
    editTax(singleTax) {
      const selectedTaxes = this.invoiceData.content.taxes.filter(a => a.id === singleTax.tax_id)
      if (selectedTaxes.length > 0) {
        this.$refs.taxEditor.showEditor(selectedTaxes[0])
      }
    },
    taxDataEditHandler(taxObj) {
      const selectedTaxes = this.invoiceData.content.taxes.filter(a => a.id === taxObj.id)

      if (selectedTaxes.length > 0) {
        this.invoiceData.content.items.forEach(val => {
          const itemTaxes = val.applied_taxes.filter(tFilter => tFilter.tax_id === taxObj.id)
          itemTaxes.forEach(iTax => {
            // eslint-disable-next-line no-undef
            selectedTaxes[0].tax_rates.forEach((oTax, index) => {
              // eslint-disable-next-line no-undef
              if (oTax === iTax.tax_rate) {
                const newTaxRateIndex = taxObj.tax_rates[index] !== undefined
                if (newTaxRateIndex) {
                  // eslint-disable-next-line no-param-reassign
                  iTax.tax_rate = taxObj.tax_rates[index]
                }
              }
            })
          })
        })
        selectedTaxes[0].tax_name = taxObj.tax_name
        selectedTaxes[0].tax_rates = taxObj.tax_rates
      }
    },
    handleItemDiscount(item) {
      const val = JSON.parse(JSON.stringify(item))
      let discountPrice = 0
      const amount = val.quantity * val.unit_price
      if (val.discount) {
        if (val.discount.enable) {
          if (val.discount.type === 1) {
            discountPrice = ((val.discount.discount / 100) * amount)
          } else if (val.discount.type === 2) {
            discountPrice = val.discount.discount
          }
        }
      }

      return amount - discountPrice
    },
    discountEditedHandler(discountData) {
      if (discountData.discount === 0) {
        this.invoiceData.content.discount = null
      } else {
        this.invoiceData.content.discount = JSON.parse(JSON.stringify(discountData))
      }
    },
    setTermsFromDueDate() {
      const invoiceDate = this.$moment(this.invoiceData.content.invoice_date)
      const dueDate = this.$moment(this.invoiceData.content.due_date)

      const durationDays = this.$moment.duration(dueDate.diff(invoiceDate)).asDays()

      this.resetTermsOptions()
      const dayIsExist = this.termsOptions.filter(val => val.value === durationDays)
      if (dayIsExist <= 0) {
        this.termsOptions.push({ value: durationDays, label: durationDays })
        this.termsOptions.sort((a, b) => (a.value > b.value ? 1 : -1))
      }
      this.selectedTerms = durationDays
      this.changeDueDateFromTerm()
    },
    resetTermsOptions() {
      this.termsOptions = JSON.parse(JSON.stringify(this.blankTermsOptions))
    },
    changeDueDateFromTerm() {
      const invoiceDate = this.$moment(this.invoiceData.content.invoice_date)

      this.invoiceData.content.due_date = invoiceDate.add(this.selectedTerms, 'days').format('YYYY-MM-DD')
    },
    fetchAccountData() {
      store
        .dispatch('app/fetchAccountData')
        .then(response => {
          this.userData = response.data.data
          this.taxesData = this.userData.tax_and_currency_info.tax_data

          this.invoiceData.content.taxes = this.taxesData
          this.invoiceData.content.currency_code = this.userData.tax_and_currency_info.currency.currency_code
          this.invoiceData.content.currency_symbol = this.userData.tax_and_currency_info.currency.symbol !== '' ? this.userData.tax_and_currency_info.currency.symbol : this.userData.tax_and_currency_info.currency.currency_code

          if (this.invoiceData.design == null) {
            this.invoiceData.design = this.userData.invoice_design
          }
          this.invoiceData.content.invoice_number = this.userData.invoice_numbering.last_invoice_number + 1
          this.invoiceData.content.company_info.name = this.userData.company_info.business_name

          this.invoiceData.content.company_info.additional_information = this.userData.company_info.additional_information
          this.invoiceData.content.company_info.address = this.userData.company_info.street_address
          this.invoiceData.content.company_info.email = this.userData.company_info.email
          this.invoiceData.content.company_info.website = this.userData.company_info.website
          this.invoiceData.content.company_info.gst = this.userData.company_info.gst
          this.invoiceData.content.company_info.wbc = this.userData.company_info.wbc
          this.invoiceData.content.company_info.phone = this.userData.company_info.phone
          this.invoiceData.content.company_info.tkn = this.userData.company_info.tax_and_currency_info.tkn
        })
        .catch(() => {
        })
    },
    editDiscount() {
      this.$refs.discountSelector.showEditor(this.invoiceData.content.discount)
    },
    productDeletedHandler(evtData) {
      const { index } = evtData
      this.invoiceData.content.items.splice(index, 1)
      this.$refs.productSelector.closeEditor()
    },
    editProduct(selectedProduct, index) {
      this.$refs.productSelector.showEditor(selectedProduct, index)
    },
    // eslint-disable-next-line no-unused-vars

    editClient() {
      this.$refs.clientSelector.showEditor(this.invoiceData.content.billing)
    },
    clientSelected(clientData) {
      this.invoiceData.content.billing = clientData

      this.$refs.clientSelector.closeEditor()
    },
    productSelectedHandler(evtData) {
      const productData = evtData.product

      const { index } = evtData
      this.$refs.productSelector.closeEditor()
      let isExist = false
      // eslint-disable-next-line no-unused-vars
      if (index !== -1) {
        isExist = true
        const val = this.invoiceData.content.items[index]

        val.quantity = productData.quantity

        val.name = productData.name

        val.description = productData.description

        val.code = productData.code

        val.unit_price = productData.unit_price

        val.unit_type = productData.unit_type

        val.unit_type_id = productData.unit_type_id

        val.withholding_tax_applies = productData.withholding_tax_applies

        val.discount = productData.discount

        val.applied_taxes = productData.applied_taxes
      }

      if (!isExist) {
        this.invoiceData.content.items.push(productData)
      }

      this.$refs.productSelector.closeEditor()
    },
    deleteClient() {
      this.invoiceData.content.billing = null
      this.$refs.clientSelector.closeEditor()
    },
    clientHoverHandle(isHovered) {
      if (isHovered) {
        this.clientHovered = true
      } else {
        this.clientHovered = false
      }
    },
    fetchInvoice(id) {
      store
        .dispatch('app-invoice/fetchInvoice', { id })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
        //  console.log()
          this.invoiceData = response.data.data
          this.taxesData = response.data.data.content.taxes

            const invoiceDate = this.$moment(this.invoiceData.content.invoice_date)
             const dueDate = this.$moment(this.invoiceData.content.due_date)

          const durationDays = this.$moment.duration(dueDate.diff(invoiceDate)).asDays()

      this.resetTermsOptions()
      const dayIsExist = this.termsOptions.filter(val => val.value === durationDays)
      if (dayIsExist <= 0) {
        this.termsOptions.push({ value: durationDays, label: durationDays })
        this.termsOptions.sort((a, b) => (a.value > b.value ? 1 : -1))
      }
          this.selectedTerms = durationDays

          this.pageIsLoading = false
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.goToBack()
            this.errorData = error.response.data
          }
        })
    },

    saveData() {
      this.errorData = null
      // eslint-disable-next-line no-unused-vars
      let dataValidated = true
      const errorObj = { message: 'Please fill in the required fields' }
      const errorsArr = {}
      if (!this.invoiceData.content.billing) {
        errorsArr.client = ['Please select a client']
        dataValidated = false
      }
      if (this.invoiceData.content.items.length <= 0) {
        errorsArr.product = ['Please select a product']
        dataValidated = false
      }
      if (!this.invoiceData.design) {
        errorsArr.design = ['Invoice design not found']

        dataValidated = false
      }
      errorObj.errors = errorsArr
      if (!dataValidated) {
        this.isSuccess = false
        this.errorData = errorObj
        return
      }
      this.$refs.formClient.validate().then(success => {
        if (success) {
          this.pageIsLoading = true
          this.overlayMsg = 'Invoice saving..'
          if (this.invoiceData.id === 0) {
            store
              .dispatch('app-invoice/addInvoice', this.invoiceData)
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.isSuccess = true
                this.errorData = null
                this.successMsg = 'Invoice  created'
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Invoice Created',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
                this.pageReady = false

                setTimeout(() => { window.location.href = `/invoices/edit/${response.data.data.id}?activetab=send` }, 1500)
                // this.goToBack()
              })
              .catch(error => {
                this.pageIsLoading = false
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                } else if (error.response.status === 402) {
                  this.$refs.refUpgradePlanModal.show()
                }
              })
          } else {
            store
              .dispatch('app-invoice/updateInvoice', this.invoiceData)
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.isSuccess = true
                this.errorData = null
                this.successMsg = 'Invoice updated'
                this.goToBack()
              })
              .catch(error => {
                this.pageIsLoading = false
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                }
              })
          }
        }
      })
    },
    resetForm() {
      this.invoiceData = JSON.parse(JSON.stringify(this.blankInvoiceData))
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.list-group-item {
  transition: all 1s
}
.price-span{
  border-bottom: 1px solid #ebe9f1;
}
.discount-span{
  border-bottom: 2px solid #000000;
}
.old-price-text{
  text-decoration: line-through;
}
@media (max-width: 800px) {
  .column-is-mobile {
    flex-direction: column;
  }
}
</style>
